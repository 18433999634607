//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-140:_408,_7440,_3504,_8853,_5960,_2984,_9852,_789;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-140')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-140', "_408,_7440,_3504,_8853,_5960,_2984,_9852,_789");
        }
      }catch (ex) {
        console.error(ex);
      }